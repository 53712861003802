<template>
  <div class="bg-white flex flex-col items-center py-10">
    <div class="text-slate-400 text-lg">
      <p>Account Settings</p>
    </div>
    <h2 class="text-atmo-primary60 text-md pb-10">Profile Picture</h2>

    <p class="mb-5">Choose a new image for your profile picture</p>
    <input
      ref="fileInput"
      type="file"
      accept="image/jpeg,image/png"
      class="bg-white py-2 px-4 border border-gray-300 rounded-full shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      :class="{ 'border-red-500 bg-red-200': fileTypeError }"
      @change="onFileInputChange"
    />
    <p v-if="fileTypeError" class="text-red-600">Must be a jpeg or png</p>
    <img
      v-if="imageURL"
      :src="imageURL"
      alt="Profile Image"
      class="h-[200px] w-[200px] rounded-full object-cover object-center mt-10 border border-gray-300"
    />
    <div
      v-else
      class="h-[200px] w-[200px] rounded-full flex justify-center items-center mt-10 border border-gray-300 bg-gray-100"
    >
      <fa-icon icon="user" class="h-16 w-16"></fa-icon>
    </div>
    <p class="h-[30px] align-middle text-[#FFB683]">
      {{ errorMessage }}
    </p>
    <button
      class="bg-atmo-primary60 text-white rounded-full px-4 mb-5 py-3"
      @click="updateProfileImage"
    >
      Save changes
    </button>
    <button
      type="button"
      class="bg-white py-2 px-4 border border-gray-300 rounded-full shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      @click="$emit('close')"
    >
      Cancel
    </button>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { VALID_IMAGE_TYPES } from '@/constants.js'

const emit = defineEmits(['update', 'close'])
const props = defineProps({
  profileImage: {
    type: String,
    required: true,
  },
  errorMessage: {
    type: [String, null],
    default: null,
  },
})
const imageURL = ref(props.profileImage)
const fileInput = ref(null)
const fileTypeError = ref(false)

const updateProfileImage = () => {
  if (fileInput.value !== null && !fileTypeError.value) {
    emit('update', fileInput.value.files[0])
  }
}

const onFileInputChange = (event) => {
  const file = event.target.files[0]
  const extension = file.name.substring(file.name.lastIndexOf('.'))
  if (Object.values(VALID_IMAGE_TYPES).indexOf(extension) > 0) {
    fileTypeError.value = false
    const reader = new FileReader()
    reader.onload = (event) => {
      imageURL.value = event.target.result
    }
    reader.readAsDataURL(file)
  } else {
    fileTypeError.value = true
  }
}
</script>

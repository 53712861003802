import { useProjectStore } from '@/store/Project.js'
import { useErrorHandling } from '@/composables/useErrorHandling'
import { useAuthStore } from '@/store/Auth'
import { useRouter } from 'vue-router'
import { sentryException } from '@/plugins/sentry.js'
import { useMetricStore } from '@/store/Metrics'
import { storeToRefs } from 'pinia'
import * as Sentry from '@sentry/vue'

/**
 * All beforeEnter prefetching helpers, use these to prefetch data before entering a page.
 * This allows us to handle routing for fetching that is dictated by the url id params.
 */

export const prefetchProject = async (to, from) => {
  const projectStore = useProjectStore()
  const { handleErrorRouting } = useErrorHandling()

  try {
    await projectStore.fetchProjectById(to.params.projectId)
  } catch (error) {
    sentryException(error, {})
    await handleErrorRouting(error)
  }
}

/**
 * Requires the project to be fetched, currently only filters the locations from the project response.
 * @param to
 * @param from
 * @returns {Promise<void>}
 */
export const prefetchActiveLocation = async (to, from) => {
  const projectStore = useProjectStore()
  const router = useRouter()

  try {
    await projectStore.setActiveLocationById(to.params.locationId)
  } catch (error) {
    sentryException(error, {})
    await router.push({ name: 'ResourceNotFoundPage' })
  }
}

export const prefetchUser = async (to, from) => {
  const authStore = useAuthStore()
  const { handleErrorRouting } = useErrorHandling()
  const router = useRouter()

  try {
    await authStore.fetchUser()
  } catch (error) {
    if (authStore.missingUser) {
      await router.push({ name: 'missingUserPage' })
    } else {
      sentryException(error, {})
      await handleErrorRouting(error)
    }
  }
  Sentry.setUser({ email: authStore.user.email })
}

export const prefetchMetricsForKpi = async (to, from) => {
  const metricStore = useMetricStore()
  const { selectedDateAction } = storeToRefs(metricStore)
  const { dates, value: timePeriod } = selectedDateAction.value || {};
  const [startTimestamp, endTimestamp] = dates || [null, null];

  const metricFetch = await metricStore.fetchMetricsForKpiById(
    to.params.kpiId,
    startTimestamp / 1000,
    endTimestamp / 1000,
    timePeriod,
  )

  const kpiFetch = await metricStore.fetchKpiById(
    to.params.kpiId,
    startTimestamp / 1000,
    endTimestamp / 1000,
    timePeriod,
  )

  await Promise.all([metricFetch, kpiFetch])
}

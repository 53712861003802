<template>
  <TransitionRoot :show="!!state?.component" as="template">
    <Dialog as="div" class="relative z-50">
      <TransitionChild
        as="template"
        enter="ease-out duration-100"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div v-if="!!state?.component" class="fixed z-40 inset-0 overflow-y-auto">
        <div
          class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-100"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="w-fit h-full px-32 max-w-4xl min-h-96 max-h-[60rem] flexborder-[32px] border-transparent transform overflow-y-auto rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <component
                :is="state?.component"
                v-bind="state?.props"
                @close="modalStore.closeModal"
              />
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
/**
 * Global component used for opening modals. Is entirely controlled via the modal store.
 * Usage example:
 * ```
 * const modalStore = useModalStore()
 * const openDashboardManagementModal = () => {
 *   modalStore.openModal({
 *     component: DashboardManagementPopup,
 *     props: { onUpdate: getLocationDashboards(), onSuccess: null, onError: null }
 *   })
 * }
 * ```
 * For now, onUpdate, onSuccess and onError are the only emitted events we'll need to fire from the modal.
 *
 * Any emit event needs to be prefixed with `on` followed by the event name capitalised.
 * Event callback example:
 *
 * Child.vue
 * ```
 * emit('update')
 * ```
 *
 * Parent.vue
 * ```
 * props: { onUpdate: someFn }
 * ```
 *
 */
import { useModalStore } from '@/store/Modal'
import { storeToRefs } from 'pinia'

import {
  TransitionRoot,
  TransitionChild,
  DialogPanel,
  Dialog,
} from '@headlessui/vue'

const modalStore = useModalStore()
const { state } = storeToRefs(modalStore)
</script>

<style scoped></style>

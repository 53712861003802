<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { provide, shallowRef } from 'vue'
import layouts from '@/layouts/app/layouts'

const layout = shallowRef()
const router = useRouter()

router.beforeEach((to) => {
  layout.value = layouts[to.meta.layout]
})

provide('app:layout', layout)
</script>

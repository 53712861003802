import { useAuthStore } from '@/store/Auth'
import Pusher from 'pusher-js'
import { sentryException } from './sentry'
import { inject } from 'vue'

const baseURL = import.meta.env.VITE_APP_API_BASE_URL
const environment = import.meta.env.VITE_APP_ENVIRONMENT
const pusherKey = import.meta.env.VITE_APP_PUSHER_KEY

const initialisePusherClient = () => {
  const authStore = useAuthStore()

  if (!authStore.token) {
    sentryException('Auth token is required to initialise Pusher.')
  }

  if (environment == 'development') {
    Pusher.logToConsole = true
  }
  const pusher = new Pusher(pusherKey, {
    channelAuthorization: {
      endpoint: `${baseURL}/channels/pusher/auth/`,
      headers: {
        Authorization: `Token ${authStore.token}`,
      },
    },
    cluster: 'eu',
  })
  /**
   * Handling the user channel subscription. This may need reworking if we introduce channels other than users.
   */
  pusher.subscribe(authStore.user.channel)
  return pusher
}

export const pusherClientKey = Symbol('pusherClient')

export const providePusherClient = (app) => {
  const pusherClient = initialisePusherClient()
  if (!pusherClient) {
    sentryException('Pusher client is not initialised.')
  }
  app.provide(pusherClientKey, pusherClient)
}

export const usePusherClient = () => {
    /**
     * Usage: 
     *      const pusher = usePusherClient()
     *      pusher.bind('event-name', (data) => {
     *          do something with data
     *      })
     */
  const pusher = inject(pusherClientKey)
  if (!pusher) {
    sentryException('Pusher client is not provided or initialised.')
  }
  return pusher
}

import { useAuthStore } from '@/store/Auth'
import { storeToRefs } from 'pinia'
import { ofetch } from 'ofetch'

export const api = ofetch.create({
  baseURL: import.meta.env.VITE_APP_API_BASE_URL,
  timeout: 10000,
  retryStatusCodes: [401],
  retry: 2,
})

/**
 * @param {string} url
 * @param {Object} opts
 * */
export const usePortalAPI = async (url, opts) => {
  const authStore = useAuthStore()
  return await api(url, {
    ...opts,
    async onRequest({ request, options }) {
      const { token } = storeToRefs(authStore)
      if (token.value) {
        options.headers = {
          ...options.headers,
          Authorization: `Token ${token.value}`,
        }
      }
    },
    async onResponse({ response }) {
      if (response.status === 401) {
        // Refresh token control
        await authStore.$refresh()
      }
      return response
    },
    async onResponseError({ response }) {
      return response
    },
  })
}

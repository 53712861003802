import { defineStore } from 'pinia'
import { ref } from 'vue'

/**
 * Will need to revist this as it could bloat quite heavily
 */
export const useSettingsStore = defineStore(
  'settingsStore',
  () => {
    const menuOpen = ref(true)
    const mapOpen = ref(true)

    return {
      menuOpen,
      mapOpen,
    }
  },
  {
    persist: {
      storage: localStorage,
    },
  },
)

<template>
  <div v-if="project.get_icon && !imageError" class="min-w-16 flex-none">
    <img
      :title="project.name"
      class="h-16 w-16 object-cover rounded-lg"
      :src="project.get_icon"
      alt="logo"
      @error="imageError = true"
    />
  </div>
  <div
    v-else
    :title="project.name"
    class="h-16 w-16 rounded-lg flex-none flex justify-center items-center bg-gray-100"
  >
    <p class="text-md pointer-events-none">{{ firstLetterOfName }}</p>
  </div>
</template>
<script setup>
import { computed, ref } from 'vue'

const props = defineProps({
  project: {
    type: Object,
    required: true,
  },
})

const imageError = ref(false)

const firstLetterOfName = computed(() => {
  return props.project.name.charAt(0)
})
</script>

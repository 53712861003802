<template>
  <li
    class="flex flex-row max-w-full items-center relative cursor-default"
    :class="{ 'justify-center': !extended }"
  >
    <div class="relative">
      <img
        v-if="user.profile_image && !imageError"
        :class="[
          extended
            ? 'min-w-[32px] min-h-[32px] max-w-[32px] max-h-[32px]'
            : 'w-[32px] h-[32px]',
          'transition object-cover rounded-full border-[2px] border-white cursor-pointer',
        ]"
        :src="user.profile_image"
        alt="profile image"
        @click="$emit('click')"
        @error="imageError = true"
      />
      <div
        v-else
        class="h-[32px] w-[32px] flex-none flex justify-center rounded-full items-center bg-gray-100 border-[2px] border-white cursor-pointer"
        @click="$emit('click')"
      >
        <p class="text-base pointer-events-none">{{ firstLetterOfName }}</p>
      </div>

      <div
        class="absolute left-6 top-6 flex flex-row items-center justify-center content-center text-black bg-white h-6 w-6 rounded-full"
      >
        <fa-icon
          icon="user-check"
          class="text-atmo-primary60 h-6 w-6 scale-[0.60]"
        />
      </div>
    </div>
    <!-- user details -->
    <div
      :class="{ hidden: !extended }"
      class="text-on-primary ml-4 max-w-[100px]"
    >
      <h5 class="text-base whitespace-nowrap text-ellipsis overflow-hidden">
        {{ formattedUser }}
      </h5>
    </div>
  </li>
</template>
<script setup>
import { computed, ref, toRef } from 'vue'

const props = defineProps({
  extended: Boolean,
  user: {
    type: Object,
    required: true,
  },
})

defineEmits(['click'])

const user = toRef(() => props.user)
const imageError = ref(false)

const formattedUser = computed(() => {
  if (user.value.is_superuser) return user.value.username
  return `${props.user.first_name} ${props.user.last_name}`
})

const firstLetterOfName = computed(() => {
  if (user.value.is_superuser)
    return user.value.username.charAt(0).toUpperCase()
  return props.user.first_name.charAt(0).toUpperCase()
})
</script>

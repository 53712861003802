import { useRouter } from 'vue-router'
import { FETCH_ERRORS } from '@/constants.js'

// TODO: create a better system for handling errors. This is pretty crude.
export const useErrorHandling = () => {
  const router = useRouter()
  /**
   *
   * @param {(Object | undefined)} error
   * @returns {Promise<void>}
   */
  const handleErrorRouting = async (error) => {
    if (error.response) {
      switch (error?.response.status) {
        case 404:
          await router.push({ name: 'ResourceNotFoundPage' })
          break
        case 500:
          await router.push({ name: 'InternalServerErrorPage' })
          break
        default:
          await router.push({ name: 'IssuePage' })
      }
    }
    if (error?.cause?.message === FETCH_ERRORS.NETWORK_ERROR) {
      await router.push({ name: 'IssuePage' })
      return
    }
    if (error?.cause?.message === FETCH_ERRORS.ABORT_ERROR) {
      await router.push({ name: 'IssuePage' })
    }
  }

  return { handleErrorRouting }
}

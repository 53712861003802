import { defineStore } from 'pinia'
import { markRaw, ref } from 'vue'

export const useModalStore = defineStore('modalStore', () => {
  const state = ref({
    component: null,
    props: {},
  })

  const openModal = ({ component, props }) => {
    state.value = { component: markRaw(component), props: props || {} }
  }

  const closeModal = () => {
    state.value = { component: null, props: {} }
  }

  return {
    state,
    openModal,
    closeModal,
  }
})

import { useAuthStore } from '@/store/Auth'
import { storeToRefs } from 'pinia'

/**
 * Used for checking if a user can access any routes that are flagged as admin only.
 * @param to
 * @param from
 * @returns {Promise<{name: string}|boolean>}
 */
export const adminGuard = async (to, from) => {
  const authStore = useAuthStore()
  const { user } = storeToRefs(authStore)
  if (user.value === null) return { name: 'loading' }
  if (user.value.is_superuser) {
    return true
  } else {
    return { name: 'loading' }
  }
}

/**
 * Used for checking if a user is authenticated before navigating to protected routes.
 * @param to
 * @param from
 * @returns {Promise<{name: string}>}
 */
export const authGuard = async (to, from) => {
  const authStore = useAuthStore()
  const { isAuthenticated } = storeToRefs(authStore)
  if (!isAuthenticated.value && to.name !== 'loginPage') {
    return { name: 'loginPage' }
  }
}

/**
 * Used for checking if a user can access the project routes.
 * @param to
 * @param from
 * @returns {Promise<{name: string}>}
 */
export const projectGuard = async (to, from) => {
  const authStore = useAuthStore()
  const { user } = storeToRefs(authStore)
  if (user.value === null) return { name: 'loading' }
  if (user.value.is_project_user || user.value.is_superuser) {
    return true
  } else {
    return { name: 'loading' }
  }
}
